<template>
  <div>
    <Loader />
    <section class="sign-in-page">
            <div class="container p-0">
                <div class="row no-gutters height-self-center">
                  <div class="col-sm-12 align-self-center page-content rounded">
                    <div class="row m-0">
                      <div class="col-sm-12 sign-in-page-data">

                          <div v-if="authUser.authInProgress" class="logo">
                            <img src="@/assets/images/logo.png" alt="logo" height="50vh" />
                            <div class="lds-facebook"><div></div><div></div><div></div></div>
                            <p class="font-size-20 pulse">Setting you up</p>
                            <p class="fade-in">This is taking a while. Please be patient.</p>
                          </div>

                         <router-view v-else></router-view>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
    </section>
  </div>
</template>
<script>
import Loader from '../../components/core/loader/Loader'
import logo from '../../assets/images/logo.png'
import { core } from '../../config/pluginInit'
import bgImage from '../../assets/images/login/2.jpg'
import { mapGetters } from 'vuex'
export default {
  name: 'AuthLayout',
  components: {
    Loader
  },
  mounted () {
    core.index()
  },
  computed: {
    ...mapGetters({
      authUser: 'Setting/authUserState'
    })
  },
  data () {
    return {
      slickOptions: {
        slidesToShow: 1,
        arrows: false
      },
      logo: logo,
      bgImageURL: bgImage
    }
  }
}
</script>
<style>
  @import url("../../assets/css/custom.css");

.fade-in {
  opacity: 0;
  animation-name: fade-in;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-delay: 3s;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.pulse {
  animation: pulse 2.5s infinite;
}

  .sign-in-page-data{
    display: grid;
    place-items: center;
  }
  .sign-in-page{
    height: 90vh;
  }

  .form-control{
    border-radius: 5px;
    border: 1px solid #a6a6a6 !important;
    color: black;
  }
  .form-control::-webkit-input-placeholder {
    color: gray !important;
    opacity: 1
  }

  .form-control::-moz-placeholder {
    color: gray !important;
    opacity: 1
  }

  .form-control:-ms-input-placeholder {
    color: gray !important;
    opacity: 1
  }

  .form-control::-ms-input-placeholder {
    color: gray !important;
    opacity: 1
  }

  .form-control::placeholder {
    color: gray !important;
    opacity: 1
}

.main-login-section {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  background: #ffffff;
}
.branding-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}
.branding-section img {
  height: 60px;
}
.sign-in-content {
  max-width: 550px;
  margin-top: 15px !important;
  border-radius: 10px;
  border: none;
  box-shadow: 0 16px 40px 1px rgba(112, 144, 176, 0.2);
  padding: 25px;
}
.sign-in-content .sign-in-title {
  font-size: 18px;
  font-weight: 700;
}
.sign-in-content .sign-in-title p {
  margin-bottom: 10px;
}
.sign-in-content .sign-in-description {
  font-size: 15px;
}
.username-details {
  display: flex;
  flex-direction: column;
}
.username-details label {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5px;
}
.username-details input {
  height: 50px;
  border: 1px solid #7CB9E8;
  border-radius: 10px;
  padding: 5px 15px;
  font-size: 16px;
}
.username-details input:focus {
  outline: none;
}

.password-details {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
.password-details label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.password-details input {
  height: 50px;
  border: 1px solid #7CB9E8;
  border-radius: 10px;
  padding: 5px 15px;
  font-size: 16px;
}
.password-details input:focus {
  outline: none;
}
.sign-in-button {
  margin-top: 20px;
}
.sign-in-form-btn {
  height: 55px;
  width: 100%;
  border: none;
  border-radius: 30px;
  background-color: #7CB9E8;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  /*cursor: pointer;*/
}
.sign-in-form span {
  margin-top: 5px;
  color: #d91500;
  font-weight: 500;
  font-size: 16px;
}

.sign-in-form span.password-error {
  margin-top: 0;
  line-height: 1;
}
.login-error {
  padding-top: 5px;
  text-align: center;
}
.password-input {
  padding-right: 30px;
}

.password-input + .las {
  padding: 8px 20px;
}
button {
  height: 55px;
  width: 100%;
  border: none;
  border-radius: 50px !important;
  background-color: #1E1915 !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
}

button:disabled {
  background-color: #a6a6a6 !important;
  cursor: not-allowed;
}

input {
  height: 50px;
  border: 2px solid #a6a6a6;
  border-radius: 10px;
  padding: 5px 15px;
  outline: 0;
  box-shadow: none !important;
  font-size: 16px;
}

.logo{
  display: grid;
  place-items: center;
}

img{
  height: 30vh;
  margin-bottom: -10%;
  margin-top: 0px !important;
}
</style>
